"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DANGEROUS_COUNTRIES = exports.ALLOWED_PROOFSPACE_COUNTRIES = exports.ALLOWED_COUNTRIES = exports.supportedCensusBySource = exports.DEFAULT_CRED_SOURCE = exports.nonChoiceStrategies = exports.credentialSourceList = exports.EMPTY_ACTION = exports.SKIP_CRED_SOURCE = void 0;
const model_1 = require("./model");
const consts_1 = require("./model/proofspace/consts");
exports.SKIP_CRED_SOURCE = 'skip';
exports.EMPTY_ACTION = '_empty';
exports.credentialSourceList = [
    exports.SKIP_CRED_SOURCE, model_1.NEWBELARUS_STRATEGY, model_1.PROOFSPACE_STRATEGY
];
exports.nonChoiceStrategies = [model_1.TELEGRAM_STRATEGY, model_1.NEWBELARUS_STRATEGY];
exports.DEFAULT_CRED_SOURCE = model_1.NEWBELARUS_STRATEGY;
exports.supportedCensusBySource = {
    [model_1.NEWBELARUS_STRATEGY]: model_1.newbelarusAllowedCensus,
    [model_1.PROOFSPACE_STRATEGY]: consts_1.proofspaceAllowedCensus
};
exports.ALLOWED_COUNTRIES = ['BY'];
exports.ALLOWED_PROOFSPACE_COUNTRIES = ['112'];
exports.DANGEROUS_COUNTRIES = ['BY', 'RU'];
