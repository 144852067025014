"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CensusStatus = exports.PollStatus = void 0;
var PollStatus;
(function (PollStatus) {
    PollStatus["UNPUBLISHED"] = "unpublished";
    PollStatus["PUBLISHED"] = "published";
    PollStatus["STARTED"] = "started";
    PollStatus["PAUSED"] = "paused";
    PollStatus["CANCELED"] = "canceled";
    PollStatus["FINISHED"] = "finished";
})(PollStatus = exports.PollStatus || (exports.PollStatus = {}));
var CensusStatus;
(function (CensusStatus) {
    CensusStatus["UNPUBLISHED"] = "unpublished";
    CensusStatus["PUBLISHED"] = "published";
})(CensusStatus = exports.CensusStatus || (exports.CensusStatus = {}));
