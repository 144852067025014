"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tgValidators = exports.CYBERVOTER_TOKEN_PREFIX = exports.CYBERVOTER_TOKEN_DELIMITER = exports.CYBERVOTER_TOKEN_CODE = exports.TG_VALIDATOR_CPARTIZANS = exports.TG_VALIDATOR_GOLOS = exports.TELEGRAM_STRATEGY = void 0;
exports.TELEGRAM_STRATEGY = 'telegram';
exports.TG_VALIDATOR_GOLOS = 'golos';
exports.TG_VALIDATOR_CPARTIZANS = 'cpartizans';
exports.CYBERVOTER_TOKEN_CODE = 'cp';
exports.CYBERVOTER_TOKEN_DELIMITER = '-';
exports.CYBERVOTER_TOKEN_PREFIX = `${exports.CYBERVOTER_TOKEN_CODE}${exports.CYBERVOTER_TOKEN_DELIMITER}`;
exports.tgValidators = [exports.TG_VALIDATOR_GOLOS, exports.TG_VALIDATOR_CPARTIZANS];
