"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrategyErrorType = exports.VotingSyntaxError = exports.CensusSyntaxError = exports.ServiceStartegyImplError = exports.CredsStartegyImplError = exports.WalletStartegyImplError = exports.StartegyImplError = exports.StartegyUnimplementedError = exports.AbstractStrategyError = void 0;
const errors_1 = require("../../utils/errors");
class AbstractStrategyError extends errors_1.LocalizedError {
    constructor(name, type, message, params) {
        super(`${name}.${type}.strategy.${message}`, Object.assign({}, params));
    }
}
exports.AbstractStrategyError = AbstractStrategyError;
class StartegyUnimplementedError extends AbstractStrategyError {
    constructor(name, type, method) {
        super(name, type, 'method.implemented', { method });
    }
}
exports.StartegyUnimplementedError = StartegyUnimplementedError;
class StartegyImplError extends StartegyUnimplementedError {
    constructor(name, method) {
        super(name, StrategyErrorType.GENERAL, method);
    }
}
exports.StartegyImplError = StartegyImplError;
class WalletStartegyImplError extends StartegyUnimplementedError {
    constructor(name, method) {
        super(name, StrategyErrorType.WALLET, method);
    }
}
exports.WalletStartegyImplError = WalletStartegyImplError;
class CredsStartegyImplError extends StartegyUnimplementedError {
    constructor(name, method) {
        super(name, StrategyErrorType.CREDS, method);
    }
}
exports.CredsStartegyImplError = CredsStartegyImplError;
class ServiceStartegyImplError extends StartegyUnimplementedError {
    constructor(name, method) {
        super(name, StrategyErrorType.SERVICE, method);
    }
}
exports.ServiceStartegyImplError = ServiceStartegyImplError;
class CensusSyntaxError extends AbstractStrategyError {
    constructor(name, message) {
        super(name, StrategyErrorType.SERVICE, `census.${message !== null && message !== void 0 ? message : 'failed'}`);
    }
}
exports.CensusSyntaxError = CensusSyntaxError;
class VotingSyntaxError extends AbstractStrategyError {
    constructor(name, message) {
        super(name, StrategyErrorType.SERVICE, `voting.${message !== null && message !== void 0 ? message : 'failed'}`);
    }
}
exports.VotingSyntaxError = VotingSyntaxError;
var StrategyErrorType;
(function (StrategyErrorType) {
    StrategyErrorType["GENERAL"] = "general";
    StrategyErrorType["WALLET"] = "wallet";
    StrategyErrorType["CREDS"] = "credentials";
    StrategyErrorType["SERVICE"] = "voting";
})(StrategyErrorType = exports.StrategyErrorType || (exports.StrategyErrorType = {}));
