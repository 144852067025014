"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VoteError = exports.VoteEmptyError = exports.AccountBalanceError = exports.PollReadyError = exports.PollError = void 0;
const errors_1 = require("../../utils/errors");
class PollError extends errors_1.LocalizedError {
    constructor(message) {
        super(message !== null && message !== void 0 ? message : 'poll.error');
    }
}
exports.PollError = PollError;
class PollReadyError extends PollError {
    constructor(message) {
        super(message !== null && message !== void 0 ? message : 'poll.ready.isnt');
    }
}
exports.PollReadyError = PollReadyError;
class AccountBalanceError extends errors_1.LocalizedError {
    constructor(message) {
        super(message !== null && message !== void 0 ? message : 'account.balance.insufficient');
    }
}
exports.AccountBalanceError = AccountBalanceError;
class VoteEmptyError extends PollError {
    constructor(message) {
        super(message !== null && message !== void 0 ? message : 'vote.empty');
    }
}
exports.VoteEmptyError = VoteEmptyError;
class VoteError extends PollError {
    constructor(message) {
        super(message !== null && message !== void 0 ? message : 'vote.casted');
    }
}
exports.VoteError = VoteError;
