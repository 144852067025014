"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildW3cPresentationRequestBuilder = void 0;
const request_1 = require("./request");
const buildW3cPresentationRequestBuilder = (challenge, type) => {
    const request = {
        challenge,
        query: []
    };
    return {
        interact: service => {
            var _a;
            request.interact = (_a = request.interact) !== null && _a !== void 0 ? _a : { service: [] };
            request.interact.service.push(service);
        },
        query: () => {
            const query = {
                type: type !== null && type !== void 0 ? type : request_1.PresentationRequestQueryType.SimpleZK,
                credentialQuery: []
            };
            request.query.push(query);
            const builder = {
                query: queryItem => {
                    if (Array.isArray(query.credentialQuery) && query.credentialQuery.length === 0) {
                        query.credentialQuery = queryItem;
                    }
                    else if (Array.isArray(query.credentialQuery)) {
                        query.credentialQuery.push(queryItem);
                    }
                    else {
                        query.credentialQuery = [query.credentialQuery, queryItem];
                    }
                    query.credentialQuery = Array.isArray(query.credentialQuery) ? query.credentialQuery : [query.credentialQuery];
                }
            };
            return builder;
        },
        domain: domain => {
            request.domain = domain;
        },
        build: () => request
    };
};
exports.buildW3cPresentationRequestBuilder = buildW3cPresentationRequestBuilder;
