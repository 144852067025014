"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INTEGRATION_OK = exports.DEFAULT_MEMBER_NAME = exports.ServiceType = void 0;
var ServiceType;
(function (ServiceType) {
    ServiceType["ONLINE"] = "online";
    ServiceType["OFFLINE"] = "offline";
})(ServiceType = exports.ServiceType || (exports.ServiceType = {}));
exports.DEFAULT_MEMBER_NAME = 'voter';
exports.INTEGRATION_OK = 'Ok';
