"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.w3cUtils = void 0;
const utils_1 = require("./vp/utils");
exports.w3cUtils = {
    presentation: {
        request: {
            builder: utils_1.buildW3cPresentationRequestBuilder
        }
    }
};
