"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NEWBELARUS_MEDIATED_REQUEST_TYPE = exports.dockNBPrefedinedActions = exports.dockNBTelegramAction = exports.dockNBPasswportAction = exports.SERVICE_PK_NEWBELARUSPASSPORT = exports.CRED_TYPE_NEWBELARUSTELEGRAM = exports.CRED_TYPE_NEWBELARUSPASSPORT = exports.newbelarusAllowedCensus = exports.NEWBELARUS_STRATEGY = void 0;
const vocdoni_1 = require("../vocdoni");
exports.NEWBELARUS_STRATEGY = 'newbelarus';
exports.newbelarusAllowedCensus = [vocdoni_1.VOCDONI_CENSUS_CSP_NBNS, vocdoni_1.VOCDONI_CENSUS_CSP_BLINDNS];
exports.CRED_TYPE_NEWBELARUSPASSPORT = 'NewBelarusPassport';
exports.CRED_TYPE_NEWBELARUSTELEGRAM = 'NewBelarusTelegram';
exports.SERVICE_PK_NEWBELARUSPASSPORT = 'Service:NewBelarusPassport:SecretKey';
/**
 * @TODO align with https://w3c-ccg.github.io/vp-request-spec/
 */
exports.dockNBPasswportAction = {
    type: exports.NEWBELARUS_STRATEGY,
    actionId: 'NewBelarusPassportPersonalNumber',
    title: 'Passport personal number verification',
    credentialsRequired: [exports.CRED_TYPE_NEWBELARUSPASSPORT],
    fieldsToReveal: [[
            'credentialSubject.personId',
            'credentialSubject.dateOfBirth',
            'credentialSubject.country',
            'credentialSubject.meta',
            'type', 'holder', 'issuer'
        ]],
    allowedIssuers: [] // Put issuers public keys here during app initialization
};
exports.dockNBTelegramAction = {
    type: exports.NEWBELARUS_STRATEGY,
    actionId: 'NewBelarusTelegramId',
    title: 'Telegram ID verification',
    credentialsRequired: [exports.CRED_TYPE_NEWBELARUSTELEGRAM],
    fieldsToReveal: [['credentialSubject.id', 'credentialSubject.hasGolos', 'type', 'holder', 'issuer']],
    allowedIssuers: []
};
exports.dockNBPrefedinedActions = [exports.dockNBPasswportAction];
exports.NEWBELARUS_MEDIATED_REQUEST_TYPE = 'MediatedMobileWalletPluginIteraction2023';
