"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anonymousUser = void 0;
const types_1 = require("../auth/types");
exports.anonymousUser = {
    _id: '',
    name: types_1.AUTH_ANONYMOUS,
    createdAt: new Date(),
    votingAddress: types_1.AUTH_ANONYMOUS,
    active: true
};
