"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterPollWithStrategy = exports.fillPagination = void 0;
const consts_1 = require("../proofspace/consts");
const fillPagination = (pager) => {
    var _a;
    if (pager != null) {
        pager = Object.fromEntries(Object.entries(pager).map(([key, value]) => {
            return [key, typeof value === 'string' ? parseInt(value) : value];
        }));
    }
    return { page: (_a = pager === null || pager === void 0 ? void 0 : pager.page) !== null && _a !== void 0 ? _a : 0, size: (pager === null || pager === void 0 ? void 0 : pager.size) == null ? 20 : pager.size > 50 ? 50 : pager.size < 1 ? 1 : pager.size };
};
exports.fillPagination = fillPagination;
const filterPollWithStrategy = (strategy) => (poll) => {
    var _a;
    return (strategy === consts_1.PROOFSPACE_STRATEGY && poll.requiredProofs == null)
        || ((_a = poll.requiredProofs) === null || _a === void 0 ? void 0 : _a.some(proof => proof.type === strategy));
};
exports.filterPollWithStrategy = filterPollWithStrategy;
