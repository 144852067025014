"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findPsCred = exports.castPsFieldsFromSubject = exports.castPassportFromPs = exports.castKeystoreFromPs = exports.castAuthFromPs = exports.castAsyncRegistrationFromPs = exports.castRegistrationFromPs = exports.castTelegramFromPs = exports.castSubjectFromPs = exports.passportPsType = exports.authPsSimple = exports.authPsType = exports.asyncRegistrationPsType = exports.telegramPsType = exports.registrationPsType = exports.keystoreType = exports.commonPsType = void 0;
exports.commonPsType = {
    issuedAt: 'Credential Issue Date'
};
exports.keystoreType = Object.assign(Object.assign({}, exports.commonPsType), { store: 'Keystore', address: 'Voting Address' });
exports.registrationPsType = Object.assign(Object.assign({}, exports.keystoreType), { token: 'Auth Code' });
exports.telegramPsType = Object.assign(Object.assign({}, exports.commonPsType), { telegramId: 'Telegram Id', nickname: 'Nickname', name: 'Name', golos: 'Golos Participant', cyberVoter: 'Cyber Voter' });
exports.asyncRegistrationPsType = {
    token: 'Auth Code',
    address: 'Voting Address'
};
exports.authPsType = Object.assign(Object.assign({}, exports.commonPsType), { token: 'Auth Code', resource: 'Resource Id' });
exports.authPsSimple = Object.assign(Object.assign({}, exports.commonPsType), { token: 'Auth Code' });
exports.passportPsType = Object.assign(Object.assign({}, exports.commonPsType), { nationalId: 'National Identification Number', countryCode: 'Country', birthdate: 'Date of Birth' });
const castSubjectFromPs = (ps, tpl) => Object.fromEntries(Object.entries(tpl).map(([key, name]) => { var _a, _b; return [key, (_b = (_a = ps.fields.find(field => field.name === name)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '']; }));
exports.castSubjectFromPs = castSubjectFromPs;
const castTelegramFromPs = (ps) => (0, exports.castSubjectFromPs)(ps, exports.telegramPsType);
exports.castTelegramFromPs = castTelegramFromPs;
const castRegistrationFromPs = (ps) => (0, exports.castSubjectFromPs)(ps, exports.registrationPsType);
exports.castRegistrationFromPs = castRegistrationFromPs;
const castAsyncRegistrationFromPs = (ps) => (0, exports.castSubjectFromPs)(ps, exports.asyncRegistrationPsType);
exports.castAsyncRegistrationFromPs = castAsyncRegistrationFromPs;
const castAuthFromPs = (ps) => (0, exports.castSubjectFromPs)(ps, exports.authPsType);
exports.castAuthFromPs = castAuthFromPs;
const castKeystoreFromPs = (ps) => (0, exports.castSubjectFromPs)(ps, exports.keystoreType);
exports.castKeystoreFromPs = castKeystoreFromPs;
const castPassportFromPs = (ps) => (0, exports.castSubjectFromPs)(ps, exports.passportPsType);
exports.castPassportFromPs = castPassportFromPs;
const castPsFieldsFromSubject = (ps, tpl) => Object.entries(tpl).map(([field, name]) => ({ name, value: ps[field] }));
exports.castPsFieldsFromSubject = castPsFieldsFromSubject;
const findPsCred = (pss, criteria) => pss.find(ps => ps.schemaId === criteria.schemaId && ps.credentialId === criteria.credentialId);
exports.findPsCred = findPsCred;
