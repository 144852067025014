"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalizedError = void 0;
class LocalizedError extends Error {
    constructor(key, params) {
        super(key);
        this.messageKey = key;
        this.messageParams = params;
    }
    toString() {
        if (LocalizedError.translator != null) {
            return LocalizedError.translator(this.messageKey, this.messageParams);
        }
        return super.toString();
    }
}
exports.LocalizedError = LocalizedError;
