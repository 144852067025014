"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkRole = exports.entitiesToIntegrationServiceAuthorization = exports.integrationParamsToEntities = void 0;
const organization_1 = require("../organization");
const types_1 = require("./types");
const integrationParamsToEntities = (config, name) => {
    var _a, _b;
    const _payload = Object.assign({ token: config.authorization.userToken, member: {
            name: name !== null && name !== void 0 ? name : (((_a = config.org) === null || _a === void 0 ? void 0 : _a.name) !== undefined
                ? `${types_1.DEFAULT_MEMBER_NAME}}:${(_b = config.org) === null || _b === void 0 ? void 0 : _b.name}`
                : types_1.DEFAULT_MEMBER_NAME),
            serviceId: config.serviceId,
            orgId: config.authorization.orgId,
            externalId: config.authorization.userId
        } }, (config.authorization.orgId != null && config.org != null
        ? {
            organization: Object.assign({ externalId: config.authorization.orgId, serviceId: config.serviceId }, config.org)
        }
        : {}));
    return _payload;
};
exports.integrationParamsToEntities = integrationParamsToEntities;
const entitiesToIntegrationServiceAuthorization = (payload) => {
    var _a;
    return {
        orgId: (_a = payload.organization) === null || _a === void 0 ? void 0 : _a.externalId,
        userId: payload.member.externalId,
        userToken: payload.token
    };
};
exports.entitiesToIntegrationServiceAuthorization = entitiesToIntegrationServiceAuthorization;
const checkRole = (role, need) => need === organization_1.MemberRole.MANAGER ? role === need : true;
exports.checkRole = checkRole;
