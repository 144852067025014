"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dockActionRequestToW3C = exports.isFullDockSchema = exports.generateDockSignatureParams = exports.expandDockSchema = exports.addExpectedIssuerToDockAction = void 0;
const consts_1 = require("./consts");
const utils_1 = require("../w3c/utils");
const addExpectedIssuerToDockAction = (actionId, issuer) => {
    const action = consts_1.dockNBPrefedinedActions.find(action => action.actionId === actionId);
    if (action != null && action.allowedIssuers != null) {
        action.allowedIssuers.push(issuer);
    }
};
exports.addExpectedIssuerToDockAction = addExpectedIssuerToDockAction;
const expandDockSchema = (schema, basic) => {
    var _a, _b;
    return Object.assign(Object.assign({}, basic), { definitions: Object.assign(Object.assign({}, basic.definitions), schema.definitions), properties: Object.assign(Object.assign(Object.assign({}, basic.properties), schema.properties), { credentialSubject: Object.assign(Object.assign(Object.assign({}, basic.properties.credentialSubject), (_a = schema.properties) === null || _a === void 0 ? void 0 : _a.credentialSubject), (((_b = schema.properties) === null || _b === void 0 ? void 0 : _b.credentialSubject) != null
                ? {
                    properties: Object.assign(Object.assign({}, basic.properties.credentialSubject.properties), schema.properties.credentialSubject.properties)
                }
                : {})) }) });
};
exports.expandDockSchema = expandDockSchema;
const generateDockSignatureParams = (Generator, schema, labelOrParams, basic) => {
    if (!(0, exports.isFullDockSchema)(schema)) {
        if (basic == null) {
            throw new Error('partial.schema');
        }
        schema = (0, exports.expandDockSchema)(schema, basic);
    }
    return Generator.getSigParamsForMsgStructure(schema, labelOrParams);
};
exports.generateDockSignatureParams = generateDockSignatureParams;
const isFullDockSchema = (schama) => schama.$schema != null;
exports.isFullDockSchema = isFullDockSchema;
const dockActionRequestToW3C = (actionRequest, reasons, origin) => {
    var _a;
    actionRequest = Array.isArray(actionRequest) ? actionRequest : [actionRequest];
    const firstRequest = actionRequest[0];
    const builder = utils_1.w3cUtils.presentation.request.builder(firstRequest.challenge);
    if (origin != null) {
        builder.domain(origin);
    }
    for (const request of actionRequest) {
        const query = builder.query();
        for (let idx = 0; idx < request.credentialsRequired.length; ++idx) {
            const type = request.credentialsRequired[idx];
            query.query({
                reason: (_a = reasons[type]) !== null && _a !== void 0 ? _a : 'reason.unknown',
                filter: {
                    type,
                    issuer: Array.isArray(request.allowedIssuers) ? request.allowedIssuers[idx] : undefined,
                },
                required: request.optionalCredentials != null && request.optionalCredentials[idx] !== undefined
                    ? !request.optionalCredentials[idx] : true,
                attributes: request.fieldsToReveal[idx]
            });
        }
    }
    // builder.interact({
    //   type: NEWBELARUS_MEDIATED_REQUEST_TYPE,
    //   serviceEndpoint: `rpc://localhost/create-presentation/${actionRequest.actionId}`
    // })
    return builder.build();
};
exports.dockActionRequestToW3C = dockActionRequestToW3C;
