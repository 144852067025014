"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBasicStrategyBuilder = void 0;
const createBasicStrategyBuilder = (context) => {
    let votingWallet;
    let credWallet;
    let service;
    const _builder = {
        voting: {
            wallet: wallet => {
                votingWallet = wallet;
                return _builder;
            },
            service: _service => {
                service = _service;
                return _builder;
            }
        },
        credentials: {
            wallet: wallet => {
                credWallet = wallet;
                return _builder;
            }
        },
        build: () => {
            const _strategy = {
                ctx: () => context,
                wallet: () => votingWallet,
                service: () => service,
                creds: () => credWallet
            };
            votingWallet.setStrategyContext(_strategy);
            credWallet.setStrategyContext(_strategy);
            service.setStrategyContext(_strategy);
            const strategy = _strategy;
            strategy.isAuthenticated = () => _strategy.creds().isAuthenticated() &&
                _strategy.wallet().isAuthenticated() &&
                _strategy.service().isAuthenticated();
            strategy.getAddress = () => __awaiter(void 0, void 0, void 0, function* () { return yield _strategy.wallet().getAddress(); });
            return strategy;
        }
    };
    return _builder;
};
exports.createBasicStrategyBuilder = createBasicStrategyBuilder;
