"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanToken = exports.isCyberVoterToken = exports.getTgMeta = void 0;
const consts_1 = require("./consts");
const getTgMeta = (poll) => { var _a, _b; return (_b = (_a = poll.requiredProofs) === null || _a === void 0 ? void 0 : _a.find(proof => proof.type === consts_1.TELEGRAM_STRATEGY)) === null || _b === void 0 ? void 0 : _b.meta; };
exports.getTgMeta = getTgMeta;
const isCyberVoterToken = (token) => token.startsWith(consts_1.CYBERVOTER_TOKEN_PREFIX);
exports.isCyberVoterToken = isCyberVoterToken;
const cleanToken = (token) => (0, exports.isCyberVoterToken)(token) ? token.split(consts_1.CYBERVOTER_TOKEN_DELIMITER, 2)[1] : token;
exports.cleanToken = cleanToken;
