"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildPresentationManager = void 0;
const buildPresentationManager = () => {
    const _sdk = new window.PresentationCreator();
    const _manager = {
        create(request) {
            return __awaiter(this, void 0, void 0, function* () {
                const json = yield _sdk.create(request);
                return json;
                // return Presentation.fromJSON(json);
            });
        },
        /**
         * @TODO Need to figure out how to do it nicer
         */
        dispose() {
            _sdk.destroy();
        },
    };
    return _manager;
};
exports.buildPresentationManager = buildPresentationManager;
