"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PresentationCreationStatus = void 0;
var PresentationCreationStatus;
(function (PresentationCreationStatus) {
    PresentationCreationStatus["CANCELED"] = "denied";
    PresentationCreationStatus["SUCCEEDED"] = "ok";
    PresentationCreationStatus["NOTFOUND"] = "not_found";
    PresentationCreationStatus["MALFORMED"] = "bad_request";
    PresentationCreationStatus["ERROR"] = "error";
})(PresentationCreationStatus || (exports.PresentationCreationStatus = PresentationCreationStatus = {}));
