"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPsAuthenticationRequired = exports.filterRequiredActionProofList = exports.filterProofspaceRequiredCreds = exports.isPsRequestProper = exports.filterProofspaceActions = exports.makeProofspaceNow = void 0;
const consts_1 = require("../vocdoni/consts");
const makeProofspaceNow = () => Math.floor((new Date().getTime() / 1000));
exports.makeProofspaceNow = makeProofspaceNow;
const filterProofspaceActions = (actions, requiredCreds, filterIds = []) => {
    if (actions != null && Array.isArray(actions)) {
        return actions.filter(action => {
            if (filterIds.includes(action.actionId)) {
                return false;
            }
            if (!action.media.some(media => media.type === 'QR')) {
                return false;
            }
            if (action.credentialsIssued.length > 0) {
                return false;
            }
            if (action.credentialsRequired.length < 2) {
                return false;
            }
            if (requiredCreds.some(credId => !action.credentialsRequired.includes(credId))) {
                return false;
            }
            return true;
        }).map(action => (Object.assign(Object.assign({}, action), { type: 'proofspace' })));
    }
    return [];
};
exports.filterProofspaceActions = filterProofspaceActions;
const isPsRequestProper = (action, code) => {
    const [actionId, actionInstanceId] = code.split('|', 2);
    return action.actionId === actionId && action.actionInstanceId === actionInstanceId;
};
exports.isPsRequestProper = isPsRequestProper;
const filterProofspaceRequiredCreds = (action, systemCreds) => action.credentialsRequired.filter(credId => !systemCreds.includes(credId));
exports.filterProofspaceRequiredCreds = filterProofspaceRequiredCreds;
const filterRequiredActionProofList = (config, kind, actions) => {
    var _a, _b;
    const filterIds = [
        (_a = config.authCred.interaction) === null || _a === void 0 ? void 0 : _a.split('|', 2)[0],
        (_b = config.regCred.interaction) === null || _b === void 0 ? void 0 : _b.split('|', 2)[0]
    ];
    const requiredIds = consts_1.VOCDONI_CSP_CENSUSES.includes(kind)
        ? [config.authCred.credentialId]
        : [config.keystoreCred.credentialId, config.authCred.credentialId];
    return (0, exports.filterProofspaceActions)(actions, requiredIds, filterIds);
};
exports.filterRequiredActionProofList = filterRequiredActionProofList;
const isPsAuthenticationRequired = (action, config) => action.credentialsRequired.includes(config.keystoreCred.credentialId);
exports.isPsAuthenticationRequired = isPsAuthenticationRequired;
