"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseElectionResults = exports.updateVocdoniPoll = void 0;
const poll_1 = require("../poll");
const updateVocdoniPoll = (poll, election) => {
    var _a, _b;
    return (Object.assign(Object.assign({}, poll), { title: election.title.default, description: (_a = election.description.default) !== null && _a !== void 0 ? _a : '', questions: ((_b = poll.questions) === null || _b === void 0 ? void 0 : _b.length) === election.questions.length ? election.questions.map(question => {
            var _a, _b;
            return ({
                title: question.title.default,
                description: (_b = (_a = question.description) === null || _a === void 0 ? void 0 : _a.default) !== null && _b !== void 0 ? _b : '',
                choices: question.choices.map(choice => ({
                    title: choice.title.default,
                    value: choice.value
                }))
            });
        }) : poll.questions }));
};
exports.updateVocdoniPoll = updateVocdoniPoll;
const parseElectionResults = (election) => {
    var _a;
    const _result = {
        externalId: election.id,
        startDate: new Date(election.startDate),
        endDate: new Date(election.endDate),
        census: {
            externalId: election.census.censusId,
            url: election.census.censusURI,
            status: election.census.isPublished ? poll_1.CensusStatus.PUBLISHED : poll_1.CensusStatus.UNPUBLISHED,
            size: (_a = election.census.size) !== null && _a !== void 0 ? _a : election.maxCensusSize,
            weight: election.census.weight,
            type: election.census.type
        },
        maxCensusSize: election.maxCensusSize,
        status: election.status,
        voteCount: election.voteCount,
        finished: election.finalResults,
        electionCount: 0,
        questions: election.questions.map(question => {
            const total = question.choices.reduce((total, choice) => { var _a; return total + parseInt((_a = choice.results) !== null && _a !== void 0 ? _a : '0'); }, 0);
            const sorterd = [...question.choices].sort((a, b) => { var _a, _b; return parseInt((_a = a.results) !== null && _a !== void 0 ? _a : '0') - parseInt((_b = b.results) !== null && _b !== void 0 ? _b : '0'); });
            let winner = sorterd.pop();
            winner = question.choices.some(choice => { var _a, _b; return parseInt((_a = choice.results) !== null && _a !== void 0 ? _a : '0') === parseInt((_b = winner === null || winner === void 0 ? void 0 : winner.results) !== null && _b !== void 0 ? _b : ''); })
                ? winner
                : undefined;
            return {
                title: question.title.default,
                description: question.title.default,
                choices: question.choices.map(choice => {
                    var _a, _b, _c, _d;
                    return ({
                        title: choice.title.default,
                        value: choice.value,
                        result: (_a = choice.results) !== null && _a !== void 0 ? _a : '',
                        count: parseInt((_b = choice.results) !== null && _b !== void 0 ? _b : '0'),
                        share: parseInt((_c = choice.results) !== null && _c !== void 0 ? _c : '0') / total,
                        winner: (winner === null || winner === void 0 ? void 0 : winner.value) === choice.value && parseInt((_d = choice.results) !== null && _d !== void 0 ? _d : '0') > 0
                    });
                })
            };
        })
    };
    return _result;
};
exports.parseElectionResults = parseElectionResults;
