"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.hexlify = exports.toKeccak256 = exports.fromBase64 = exports.hexToBase58 = exports.toBase64 = exports.getWalletUtils = exports.randomToken = exports.advancedHash = exports.hash = exports.hydarteArgon = exports.hydrateEthers = void 0;
// import * as ethers from 'ethers'
// let _ethers: any
// export const hydrateEthers = (eth: any) => {
//   _ethers = eth
// }
let ethers;
const hydrateEthers = (eth) => {
    ethers = eth;
};
exports.hydrateEthers = hydrateEthers;
let argon2;
const hydarteArgon = (argon) => {
    argon2 = argon;
};
exports.hydarteArgon = hydarteArgon;
const hash = (salt, value) => (0, exports.hexToBase58)(ethers.sha512(ethers.toUtf8Bytes(`${salt}${value}`)));
exports.hash = hash;
const advancedHash = (salt, value) => __awaiter(void 0, void 0, void 0, function* () { return ethers.encodeBase64(yield argon2(value, salt, { t: 1, m: 65536, p: 1 })); });
exports.advancedHash = advancedHash;
const randomToken = () => ethers.encodeBase58(ethers.randomBytes(32));
exports.randomToken = randomToken;
const getWalletUtils = () => ethers.Wallet;
exports.getWalletUtils = getWalletUtils;
const toBase64 = (value) => ethers.encodeBase64(ethers.toUtf8Bytes(value));
exports.toBase64 = toBase64;
const hexToBase58 = (hex) => ethers.encodeBase58(ethers.getBytes(hex));
exports.hexToBase58 = hexToBase58;
const fromBase64 = (value) => ethers.toUtf8String(ethers.decodeBase64(value));
exports.fromBase64 = fromBase64;
const toKeccak256 = (value) => ethers.keccak256(ethers.getBytes(value));
exports.toKeccak256 = toKeccak256;
const hexlify = (value) => ethers.hexlify(value);
exports.hexlify = hexlify;
